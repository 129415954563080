import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Pagination, Autoplay, Navigation, Keyboard } from 'swiper/modules';
// import WOW from 'wowjs';

import CountUp from 'react-countup';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Button, Label } from 'reactstrap';
import { Parallax, useParallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import BlogVideo from '../assets/images/blog_video.mp4';
import BlogVideo2 from '../assets/images/blog_video-2.mp4';
import Map from './Map';


export default function Home() {
    const swiperRef1 = useRef();
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const parseTitle = (title) => {
        const url = title.toLowerCase().replace(/[^a-z0-9]+/g, '-');
        return url;
    }

    return (
        <>
            <div className='banner-slider'>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                        delay: 9500,
                        disableOnInteraction: false,
                    }}
                    navigation={false}
                    modules={[Autoplay]}
                    className=" home-slider"
                >
                    <SwiperSlide>
                        <div className='banner-section'>

                            {/* <video className='web-banner' autoPlay loop muted>
                                <source src={homeVideo} type='video/mp4' />
                            </video> */}
                            <img src={require('../assets/images/home/banner-1.jpg')} className='web-banner' alt='banner' />
                            <div className='banner-content padding-left-right'>
                                <div className='dot-img'>
                                    {/* <img src={require('../assets/images/white-dot.png')} alt='white-dot' /> */}
                                </div>
                                <h6 className='sub-heading' style={{ color: 'white' }}> VIKASH STEEL</h6>
                                <h1 className='heading' style={{ color: 'white' }}>Authorized channel partner of APL Apollo Tubes Ltd & Sambhv Steel Limited</h1>
                                <p className='paragraph' style={{ fontSize: '23px', color: 'white' }}>Forging Indias Future with Premium Steel Tubes & Pipe Product Solutions </p>
                                <Link to='/about' className='btn-class'>
                                    Get Started
                                    <span>Get Started</span>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='banner-section'>
                            <img src={require('../assets/images/banner-3.jpg')} className='web-banner' alt='banner' />
                            <div className='banner-content padding-left-right'>
                                <div className='dot-img'>
                                </div>
                                <h6 className='sub-heading' style={{ color: 'white' }}> VIKASH STEEL</h6>
                                <h1 className='heading' style={{ color: 'white' }}>Providing Excellence In Steel Trading </h1>
                                <p className='paragraph' style={{ fontSize: '23px', color: 'white' }}>Get associated with trusted partner for best in industry steel trade distribution
                                </p>
                                <Link to='/about' className='btn-class'>
                                    Get Started
                                    <span>Get Started</span>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='banner-section'>
                            <img src={require('../assets/images/home/banner-2.jpg')} className='web-banner' alt='banner' />
                            <div className='banner-content padding-left-right'>
                                <div className='dot-img'>
                                </div>
                                <h6 className='sub-heading' style={{ color: 'white' }}> VIKASH STEEL</h6>
                                <h1 className='heading' style={{ color: 'white' }}>Unifying Forces for Success</h1>
                                <p className='paragraph' style={{ fontSize: '23px', color: 'white' }}>Customized Solutions for Every Industry, Harnessing Our Cross-Sector Expertise and Unrivaled Dealer Network</p>
                                <Link to='/about' className='btn-class'>
                                    Get Started
                                    <span>Get Started</span>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='banner-section'>
                            <img src={require('../assets/images/home/banner-3.jpg')} className='web-banner' alt='banner' />
                            <div className='banner-content padding-left-right'>
                                <div className='dot-img'>
                                </div>
                                <h6 className='sub-heading' style={{ color: 'white' }}> VIKASH STEEL</h6>
                                <h1 className='heading' style={{ color: 'white' }}>Authorized channel partner of APL Apollo Tubes Ltd & Sambhv Steel Limited</h1>
                                <p className='paragraph' style={{ fontSize: '23px', color: 'white' }}>Unifying Forces for Success
                                    Customized Solutions for Every Industry, Harnessing Our Cross-Sector Expertise and Unrivaled Dealer Network </p>
                                <Link to='/about' className='btn-class'>
                                    Get Started
                                    <span>Get Started</span>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <div className='banner-section '>

                            <img src={require('../assets/images/banner-2.jpg')} className='web-banner' alt='banner-2' />
                            <div className='banner-content padding-left-right'>
                                <div className='dot-img'>
                                    <img src={require('../assets/images/white-dot.png')} alt='white-dot' />
                                </div>
                                <h6 className='sub-heading'>BRAND STREET INTEGRATED </h6>
                                <h1 className='heading'>Empowering Brands<br />
                                    & Turning Audiences<br />
                                    Into Advocates</h1>
                                <p className='paragraph'>Brand Street Integrated empowers brands by fostering deep connections and inspiring action. Through innovative strategies, we cultivate lasting relationships and create enthusiastic brand advocates.</p>
                                <a href='#' className='btn-class'>
                                    Get Started
                                    <span>Get Started</span>
                                </a>
                            </div>
                        </div>
                    </SwiperSlide> */}
                </Swiper>
            </div>

            <section className='top bottom padding-left-right about-section-1 laptop-tab-view-about'>
                <div className='header-title text-left bottom'>
                    <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'center' }}>Delivering Top-Quality Steel That Exceeds Expectations</h6>
                </div>
                <div className='container-fluid' style={{ marginRight: '0px' }}>
                    <div className='about-image'>
                        <div className='about-content'>
                            <h6 className='sub-heading wow fadeInUp'>About Vikash Steel  </h6>
                            <p className='wow fadeInUp'>Welcome to Vikash Steel Tube Pvt Ltd. We are the Leading supplier Of Structural Steel
                                Tubes &amp; Pipes, MS Angle, Channel, Beam, HR Sheet, HR Coil, C Purlin, Z Purlin &amp; colour coated products from last 30 years with a
                                strong commitment to seamless supply chain in North India steel market.
                                <br />
                                Under the visionary and leadership of MR. Vikash Gupta, we are continuous a frontrunner in North
                                India’s steel trading landscape. Our strategic expansion and team efforts have to create a new
                                remarkable success in steel industry.
                            </p>

                            <Link to="/about">
                                <button>Discover More <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                </button>
                            </Link>
                        </div>
                        <div className='about-right-image'>
                            <img src={require('../assets/images/about.png')} alt='about' />
                        </div>
                    </div>
                </div>
            </section>

            <section className='bottom padding-left-right about-section-1 mobile-view-about'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8 order_1'>
                            <div className='left-section'>
                                <div className='bg_overly'></div>
                                <h6 className='sub-heading wow fadeInUp'>About Vikash Steel </h6>
                                <img src={require('../assets/images/about.png')} alt='abot' />
                            </div>
                        </div>
                        <div className='col-lg-4 order_2'>
                            <div className='right-section about_content'>
                                <br />
                                <p className='wow fadeInUp'>Welcome to Vikash Steel Tube Pvt Limited. We are the Leading supplier Of Structural Steel
                                    Tubes &amp; Pipes, MS Angle, Channel, Beam, HR Sheet, HR Coil, C Purlin, Z Purlin &amp; colour coated products from last 30 years with a
                                    strong commitment to seamless supply chain in North India steel market.
                                    <br />
                                    Under the visionary and leadership of MR. Vikash Gupta, we are continuous a frontrunner in North
                                    India’s steel trading landscape. Our strategic expansion and team efforts have to create a new
                                    remarkable success in steel industry.
                                </p>
                                <button>Discover More <i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='bottom service-section'>
                <div className='service-child-section'>
                    <img src={require('../assets/images/bg-tr.png')} alt='bg' className='bg-banner' />
                    <div className='header-title text-center'>
                        <h6 className='sub-heading wow fadeInUp'>Key Projects </h6>
                        {/* <h2 className='heading wow fadeInUp'>Let's Talk Marketing</h2> */}
                    </div>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                        }}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper marketing-slider"
                    >
                        <SwiperSlide>
                            <div className='card-box'>
                                <Link to='/'>
                                    <div className='card-img'>
                                        <img src={require('../assets/images/project/delhi-metro.webp')} alt="ser-1" />
                                    </div>
                                    <h4>Delhi Metro</h4>
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-box'>
                                <Link to='/'>
                                    <div className='card-img'>
                                        <img src={require('../assets/images/project/indian-oil.webp')} alt="ser-1" />
                                    </div>
                                    <h4>Indian Oils</h4>
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-box'>
                                <Link to='/'>
                                    <div className='card-img'>
                                        <img src={require('../assets/images/project/airport.webp')} alt="ser-1" />
                                    </div>
                                    <h4>Jewar Airports</h4>
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-box'>
                                <Link to='/'>
                                    <div className='card-img'>
                                        <img src={require('../assets/images/project/ntpc.webp')} alt="ser-1" />
                                    </div>
                                    <h4>NTPC</h4>
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-box'>
                                <Link to='/'>
                                    <div className='card-img'>
                                        <img src={require('../assets/images/project/delhi-airport.webp')} alt="ser-1" />
                                    </div>
                                    <h4>Delhi Airport</h4>
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-box'>
                                <Link to='/'>
                                    <div className='card-img'>
                                        <img src={require('../assets/images/project/skywalk.webp')} alt="ser-1" />
                                    </div>
                                    <h4>ITO Sky Walk</h4>
                                </Link>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    {/* <div className='explain-more wow fadeInUp'>
                        <Link to='/product' className='btn-class'>
                            All Products
                            <span>All Products</span>
                        </Link>
                    </div> */}
                </div>
            </section>

            {/* Our segement section */}
            <section className='padding-left-right top bottom'>
                <div className="container"><div className="row"><div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="about-content animatedParent">
                        <h3 className="animated fadeInUp" style={{ fontWeight: 'bold', fontSize: '2.3rem' }}>Market Segment</h3>
                        <p className="animated fadeInUp">We are prominent leaders in steel industry providing best in class products to different market segments in India</p>
                    </div>
                    <div className="image-content animatedParent">
                        <div className="image-item1  animated fadeInLeft slow">
                            <a href="#" title="Applications of stainless steel">
                                <img className="zoom img-responsive" src={require('../assets/images/segment/retail.webp')} alt="Applications of stainless steel" title="Applications of stainless steel" />
                                <h4 className="txt-bglay">Dealers & Retailers</h4>
                            </a>
                        </div>
                    </div>
                </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="image-content animatedParent">
                            <div className="image-item2  animated fadeInRight slow2">
                                <a href="#" title="Magic of stainless steel">
                                    <img className="zoom img-responsive" src={require('../assets/images/segment/builder.webp')} alt="Magic of stainless steel" title="Magic of stainless steel" />
                                    <h4 className="txt-bglay">Architects &amp; Builders</h4>
                                </a>
                            </div>
                            <div className="image-item3  animated fadeInRight slow3">
                                <a href="#" title="Making of stainless steel">
                                    <img className="zoom img-responsive" src={require('../assets/images/segment/third-segment.webp')} alt="Making of stainless steel" title="Making of stainless steel" />
                                    <h4 className="txt-bglay">Contractors</h4>
                                </a>
                            </div>
                            <div className="image-item4  animated fadeInRight slow4">
                                <a href="#" title="Stainless Academy">
                                    <img className="zoom img-responsive" src={require('../assets/images/segment/fab.webp')} alt="Stainless Academy" title="Stainless Academy" />
                                    <h4 className="txt-bglay">Fabricators</h4>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>

            {/* Our Product Start*/}

            <section className='about-section-4 bottom padding-left-right team-section-1' style={{ background: 'rgb(250 249 249)' }}>
                <div className='container'>
                    <div className='header-title header-center mb-0'>
                        <h2 className='heading'>Our Products</h2>
                    </div>
                    <div className='row bottom top'>
                        <div className="col-lg-3 col-md-6 col-12">
                            <Link to="/structural-teel-tubes-and-pipes" style={{ textDecoration: 'none' }}>
                                <div className='value-product-logo'>
                                    <img src={require('../assets/images/home-product/setshelving-box-profile-2.webp')} alt="" />
                                    <h4 style={{ textAlign: 'center', fontSize: '19px' }}>Structural Steel Tubes & Pipes</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <Link to="/ms-pipes" style={{ textDecoration: 'none' }}>
                                <div className='value-product-logo'>
                                    <img src={require('../assets/images/home-product/ms-pipes.webp')} alt="" />
                                    <h4 style={{ textAlign: 'center', fontSize: '19px' }}>MS Pipes</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <Link to="/angle-channel-beam" style={{ textDecoration: 'none' }}>
                                <div className='value-product-logo'>
                                    <img src={require('../assets/images/home-product/angle-channel-beam.webp')} alt="" />
                                    <h4 style={{ textAlign: 'center', fontSize: '19px' }}>Angle | Channel | Beam</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <Link to="/colour-coated-projects" style={{ textDecoration: 'none' }}>
                                <div className='value-product-logo'>
                                    <img src={require('../assets/images/home-product/coated/home-coated.webp')} alt="" />
                                    <h4 style={{ textAlign: 'center', fontSize: '19px' }}>Colour Coated Products</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <Link to="/ms-sheet" style={{ textDecoration: 'none' }}>
                                <div className='value-product-logo'>
                                    <img src={require('../assets/images/home-product/ms-sheet/ms-shee.webp')} alt="ms-sheet" />
                                    <h4 style={{ textAlign: 'center', fontSize: '19px' }}>MS Sheet & Checkered Sheet</h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            {/* End */}

            {/* Certificate Page Start */}

            <section className='about-section-4 padding-left-right team-section-1'>
                <div className='container'>
                    <div className='header-title header-center mb-0'>
                        <h2 className='heading'>Our Certificate</h2>
                    </div>
                    <div className='row bottom'>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../assets/images/certificate/certificate-1.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../assets/images/certificate/certificate-2.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../assets/images/certificate/certificate-3.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../assets/images/certificate/certificate-4.webp')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Certificate Page End */}

            {/* Fascinating Stories Section */}

            {/* <section className='bottom top padding-left-right featured-section portfolio_section'>
                <div className='container'>
                    <div className='header-title mb-40px'>
                        <div>
                            <center><h2 className='heading wow fadeInUp' style={{ color: '#00207b' }}>Our Latest Blogs</h2></center>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='portfolio-slider'>
                            <Swiper
                                slidesPerView={1.3}
                                spaceBetween={30}
                                navigation={false}
                                onBeforeInit={(swiper) => {
                                    swiperRef1.current = swiper;
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 2.8,
                                        spaceBetween: 10,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                    },
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Navigation, Autoplay]}
                                className="portfolioSwiper"
                            >

                                <SwiperSlide>
                                    <div className="col-md-10">
                                        <Link target="_blank" to="/blog"
                                            className='news_link-box'>
                                            <div className="card video-section-part h-100">
                                                <video className='web-banner' autoPlay loop muted>
                                                    <source src={BlogVideo} type='video/mp4' />
                                                </video>
                                                <div className="card-body">
                                                    <p className="card-text">Upcoming Steel Plant in Raipur by Vikash Steel Tubes Pvt. Ltd....</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                            <div className="col">
                                <Link target="_blank" to={"/blog-vikash-steel"}
                                    className='news_link-box'>
                                    <div className="card h-100">
                                        <img src={require('../assets/images/news/blog-update-1.webp')} alt="blog-1" />
                                        <div className="card-body">
                                            <p className="card-text">Vikash Steel Integrated: Pioneering Experiential Marketing... </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="col">
                                <Link target="_blank" to={"/blog-awards"}
                                    className='news_link-box'>
                                    <div className="card h-100">
                                        <img src={require('../assets/images/news/blog-update-2.jpg')} alt="blog-2" />
                                        <div className="card-body">
                                            <p className="card-text">Vikash Steel Integrated: Pioneering Experiential Marketing... </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </SwiperSlide>
                            </Swiper>
                            <div className='cuttom-slider-button-box'>
                                <button onClick={() => swiperRef1.current?.slidePrev()} className="swiper-button-prev">
                                    <i className="fa-solid fa-chevron-left" style={{ color: '#f49f22' }}></i>
                                </button>
                                <button onClick={() => swiperRef1.current?.slideNext()} className="swiper-button-next">
                                    <i className="fa-solid fa-chevron-right" style={{ color: '#f49f22' }}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className='about-section-4 padding-left-right team-section-1'>
                <div className='container'>
                    <div className='header-title header-center mb-0'>
                        <h2 className='heading'>Our Latest Blogs</h2>
                    </div>
                    <div className='row bottom'>

                        <div className="col-lg-3 col-md-6 col-12">
                            <Link target="_blank" to="/blog">
                                <div className='value-product-logo'>
                                    <video className='web-banner' autoPlay loop muted>
                                        <source src={BlogVideo} type='video/mp4' />
                                    </video>
                                    <p>Upcoming latest warehouse in Raipur by Vikash Steel Tubes Pvt Ltd....</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className='padding-left-right bottom location-section'>
                <div className='container-fluid'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'>Our Branches</h3>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4'>
                            <div className='address-left'>
                                <div className='address-box'>
                                    <h6>Pipe Market Ghaziabad</h6>
                                    <p style={{ fontSize: '14px' }}>Plot -88 Prakash Industrial Estate, Sahibabd - Ghaziabad <br /><b>Contact No : 8130191517</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Lohamandi, Ghaziabad</h6>
                                    <p style={{ fontSize: '14px' }}>C-72/1/2, Lohamandi ,Industrial Area-  Ghaziabad - UP<br /> <b>Contact No : 8130191521</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Sikandrabad</h6>
                                    <p style={{ fontSize: '14px' }}>Plot No.D-37, Gopalpur, UPSIDA, Near Jain Dharam Kanta , Sikandrabad - UP<br /> <b>Contact No : 8510007640</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Raipur</h6>
                                    <p style={{ fontSize: '14px' }}>Plot No.265/1, 268/1, Behind  J K Video Hall, Dharsiwa - Raipur<br /> <b>Contact No : 7489924744, 7489924741</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Indore</h6>
                                    <p style={{ fontSize: '14px' }}>74, Devguradia Road, Vyapar Vikas Parisar, Indore<br /> <b>Contact No : 7987301846</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Odisha</h6>
                                    <p style={{ fontSize: '14px' }}>Plot No-40, Badadanpur, Puri By Pass Road - Bhubaneswar<br /> <b>Contact No : 9329928089</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Hyderabad</h6>
                                    <p style={{ fontSize: '14px' }}>5-4-23/306, 307, 3rd Floor, Ispat Bhawan, Distellery Road, Ranigunj, Sicundarabad - Hyderabad<br /> <b>Contact No : 9032002475</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Vijayavada</h6>
                                    <p style={{ fontSize: '14px' }}>Door No-76-16-9/A, Bhawanipuram - Vijayavada<br /> <b>Contact No : 9328828085</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Vizag</h6>
                                    <p style={{ fontSize: '14px' }}>A-72, Block -B, Autonagar, Vizag - AP<br /> <b>Contact No : 9329928080</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Anantapur</h6>
                                    <p style={{ fontSize: '14px' }}>D.NO. 78/2 & 78/3, Beside kesava Reddy School, Near Vediampeta - Anantapur<br /> <b>Contact No : 9032002473</b></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8'>
                            <Map />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
