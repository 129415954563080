import React from "react";
import { Link } from "react-router-dom";

const HeaderNavbar = () => {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <Link className="navbar-brand" to="/"><img src={require('../assets/images/logo.webp')} style={{ height: '90px' }} /> </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                        </ul>
                        <form className="form-inline my-2 my-lg-0" style={{ marginRight: '70px' }}>
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/">Home </Link>
                                </li>
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/about">About</Link>
                                </li>
                                <li className="nav-item active dropdown">
                                    <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Our Products
                                    </Link>
                                    <div className="dropdown-menu" style={{ border: 'none', background: 'rgb(248 249 250)' }} aria-labelledby="navbarDropdown">
                                        {/* <Link className="dropdown-item" to="/product">All Products</Link> */}
                                        <Link className="dropdown-item" to="/structural-teel-tubes-and-pipes">Structural Steel Tubes & Pipes</Link>
                                        <Link className="dropdown-item" to="/ms-pipes">MS Pipes</Link>
                                        <Link className="dropdown-item" to="/angle-channel-beam">Angle|Channel|Beam</Link>
                                        <Link className="dropdown-item" to="/colour-coated-projects">Colour Coated Products</Link>
                                        <Link className="dropdown-item" to="/ms-sheet">MS Sheet</Link>
                                        <Link className="dropdown-item" to="/value-added-product">Value Added Product</Link>
                                    </div>
                                </li>
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/csr-initiative">CSR Initiative</Link>
                                </li>
                                {/* <li className="nav-item active">
                                    <Link className="nav-link" to="/contact">Contact Us</Link>
                                </li> */}
                                {/* <li className="nav-item active">
                                    <div className="social-icons">
                                        <i className="fa-brands fa-facebook"></i>
                                        <i className="fa-brands fa-instagram"></i>
                                        <i className="fa-brands fa-x-twitter"></i>
                                        <i className="fa-brands fa-youtube"></i>
                                    </div>
                                </li> */}
                                <li className="nav-item active">
                                    <Link class="explore-btn" style={{ textDecoration: 'none' }} to="/contact"> Contact Us</Link>
                                </li>

                                <li className="nav-item active" >
                                    <Link className="nav-link" to="tel:18005695695"><i className="fa-solid fa-phone"></i> 18005695695</Link>
                                </li>
                                {/* <li>
                                    <Link class="explore-btn" style={{ textDecoration: 'none' }} to="tel:18005695695"><i className="fa-solid fa-phone"></i> 18005695695</Link>
                                </li> */}
                                {/* <li>
                                    <div className="contact-detail">
                                        <div className='social_media'>
                                            <ul>
                                                <li className='' data-wow-delay="0.1s">
                                                    <a href="" target='_blank' className="fb"><i className="fa-brands fa-facebook"></i></a>
                                                </li>
                                                <li className='' data-wow-delay="0.2s">
                                                    <a href="" target='_blank' className="insta"><i className="fa-brands fa-instagram"></i></a>
                                                </li>
                                                <li className='' data-wow-delay="0.4s">
                                                    <a href="" target='_blank' className="twitter"><i className="fa-brands fa-x-twitter"></i></a>
                                                </li>
                                                <li className='' data-wow-delay="0.5s">
                                                    <a href="" target='_blank' className="twitter"><i className="fa-brands fa-youtube"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li> */}
                            </ul>
                        </form>
                    </div>
                </div>
            </nav>
            {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/"><img src={require('../assets/images/logo.webp')} style={{ height: '70px' }} /> </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                        </ul>
                        <form className="form-inline my-2 my-lg-0" style={{ marginRight: '70px' }}>
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/">Home </Link>
                                </li>
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/about">About</Link>
                                </li>
                                <li className="nav-item active dropdown">
                                    <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Our Products
                                    </Link>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="dropdown-item" to="/product">All Products</Link>
                                        <Link className="dropdown-item" to="/product/erw-tubes-pipes">Erw Tubes And Pipes</Link>
                                        <Link className="dropdown-item" to="/product/ms-angle">MS Angle</Link>
                                        <Link className="dropdown-item" to="/product/channel-beam">Channel Beam</Link>
                                        <Link className="dropdown-item" to="/product/hr-sheet">HR Sheet</Link>
                                        <Link className="dropdown-item" to="/product/hr-coil">HR Coil</Link>
                                    </div>
                                </li>
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/contact">Contact Us</Link>
                                </li>
                                <li className="nav-item active">
                                    <Link className="nav-link" to="tel:18005695695">1800 569 5695</Link>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </nav> */}
        </>
    )
}

export default HeaderNavbar;