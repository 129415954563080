import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Parallax, useParallax } from 'react-scroll-parallax';
import InnerImage from '../assets/images/about-check.webp';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Map from './Map';


export default function About() {
    const swiperRef1 = useRef();
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [testi, setTesti] = useState([]);


    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>

            <section className='inner-banner' style={{ backgroundImage: `url(${InnerImage})` }}>
                <h1 className='heading'>About Us</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">About us</li>
                    </ol>
                </nav>
            </section>

            <section className='top bottom padding-left-right about-section-1 '>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 order_2'>
                            <div className='left-section'>
                                <div className='bg_overly'></div>
                                <div className='top-section-box top-section-box-one'>
                                    <div className='circle-box first same_box'>
                                        <img src={require('../assets/images/home-product/ms-pipes.webp')} alt='' />
                                    </div>
                                    <div className='circle-box second same_box1'>
                                        <img src={require('../assets/images/about-3.webp')} alt='' />
                                    </div>
                                </div>
                                <div className='top-section-box top-section-box-two'>
                                    <div className='circle-box three same_box1'>
                                        <img src={require('../assets/images/about-2.webp')} alt='' />
                                    </div>
                                    <div className='circle-box four same_box'>
                                        <img src={require('../assets/images/circle-1.webp')} alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 order_1'>
                            <div className='right-section about_content'>
                                <h2 className='heading wow fadeInUp' style={{ fontSize: '16px' }}>Know More About Us</h2>
                                <h6 className='sub-heading wow fadeInUp' style={{ color: '#0b164e' }}>VIKASH STEEL TUBE PVT LTD</h6>

                                <p className='paragraph p1 fadeInUp'>Vikash Steel Tube Pvt Ltd understands the balance between the heart and mind of the consumer, delivering top-quality steel products that meet the highest industry standards. We supply our vendors with steel solutions that ensure performance and reliability.</p>
                                <p className='paragraph p1 fadeInUp'>We have been a leading supplier of structural steel tubes and pipes, MS angles, channels, beams, HR sheets, HR coils, C purlins, Z purlins, and color-coated products for the past 30 years, with a strong focus on maintaining a seamless supply chain in North India’s steel market.
                                    Under the visionary leadership of Mr. Vikash Gupta, we continue to be a frontrunner in North India's steel trading industry. Our strategic expansion and dedicated team have paved the way for remarkable success, solidifying our position as a key player in the steel sector.</p>
                                <p className='paragraph p1 fadeInUp'>Our commitment to excellence drives us to provide the finest materials and innovative solutions, ensuring your projects’ success. With a blend of tradition and modern advancements, Vikash Steel Tube Pvt Ltd aims to enhance your construction and renovation experiences.
                                    Specializing in premium steel tubes, we prioritize innovation and sustainability, delivering durable solutions for a variety of applications. Our focus on quality and reliability ensures customer satisfaction every step of the way.</p>
                                <p className='paragraph p1 fadeInUp'>Join us on this journey where integrity, trust, and innovation come together to create an extraordinary steel experience. As a leading steel supplier, Vikash Steel Tube Pvt Ltd is dedicated to shaping the future of steel with a steadfast commitment to excellence.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container vision-mission-section">
                <div className='header-title header-center'>
                    <h3 className='sub-heading'>Vision & Mission</h3>
                </div>
                <div className="row">
                    {/* Vision */}
                    <div className="col-md-6">
                        <div className="vision-box angled-box">
                            <div className="icon-wrapper">
                                <img src={require('../assets/images/vision.webp')} alt="Vision Icon" className="icon" />
                            </div>
                            <div className="content">
                                <h3>Our Vision</h3>
                                <p>
                                    Our vision is to become a leading force in the steel industry by supplying 2,00,000 metric tonnes of high-quality steel annually across India. We aim to set new benchmarks in the industry with innovative solutions, reliable products, and an unwavering commitment to excellence. Through continuous growth and expansion, we aspire to serve diverse sectors and contribute to India’s industrial development while maintaining the highest standards of quality and customer satisfaction.</p>
                            </div>
                        </div>
                    </div>

                    {/* Mission */}
                    <div className="col-md-6">
                        <div className="mission-box angled-box">
                            <div className="icon-wrapper">
                                <img src={require('../assets/images/mission.webp')} alt="Mission Icon" className="icon" />
                            </div>
                            <div className="content">
                                <h3>Our Mission</h3>
                                <p>
                                    To consistently innovate , expand and expertise to deliver a quality product & services in the steel market.
                                    Our goal is to continuously innovate our operations, expand our reach, and empower our team to deliver high-quality products and services, ensuring exceptional customer experiences and long-lasting satisfaction.
                                    Over the years, we have achieved an annual supply of 2,40,000 MT of tubes and pipes across India, while operating over 8 branches and warehouses.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className='padding-left-right top bottom'>
                <div className="container"><div className="row"><div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="about-content animatedParent">
                        <h3 className="animated fadeInUp" style={{ fontWeight: 'bold', fontSize: '2.3rem' }}>Market Segment</h3>
                        <p className="animated fadeInUp">We are prominent leaders in steel industry providing best in class products to different market segments in India</p>
                    </div>
                    <div className="image-content animatedParent">
                        <div className="image-item1  animated fadeInLeft slow">
                            <a href="#" title="Applications of stainless steel">
                                <img className="zoom img-responsive" src={require('../assets/images/segment/retail.webp')} alt="Applications of stainless steel" title="Applications of stainless steel" />
                                <h4 className="txt-bglay">Dealers & Retailers</h4>
                            </a>
                        </div>
                    </div>
                </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="image-content animatedParent">
                            <div className="image-item2  animated fadeInRight slow2">
                                <a href="#" title="Magic of stainless steel">
                                    <img className="zoom img-responsive" src={require('../assets/images/segment/builder.webp')} alt="Magic of stainless steel" title="Magic of stainless steel" />
                                    <h4 className="txt-bglay">Architects &amp; Builders</h4>
                                </a>
                            </div>
                            <div className="image-item3  animated fadeInRight slow3">
                                <a href="#" title="Making of stainless steel">
                                    <img className="zoom img-responsive" src={require('../assets/images/segment/third-segment.webp')} alt="Making of stainless steel" title="Making of stainless steel" />
                                    <h4 className="txt-bglay">Contractors</h4>
                                </a>
                            </div>
                            <div className="image-item4  animated fadeInRight slow4">
                                <a href="#" title="Stainless Academy">
                                    <img className="zoom img-responsive" src={require('../assets/images/segment/fab.webp')} alt="Stainless Academy" title="Stainless Academy" />
                                    <h4 className="txt-bglay">Fabricators</h4>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section className='padding-left-right bottom location-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4'>
                            <div className='address-left'>
                                <div className='address-box'>
                                    <h6>Pipe Market Ghaziabad</h6>
                                    <p style={{ fontSize: '14px' }}>Plot -88 Prakash Industrial Estate, Sahibabd - Ghaziabad <br /><b>Contact No : 8130191517</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Lohamandi, Ghaziabad</h6>
                                    <p style={{ fontSize: '14px' }}>C-72/1/2, Lohamandi ,Industrial Area-  Ghaziabad - UP<br /> <b>Contact No : 8130191521</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Sikandrabad</h6>
                                    <p style={{ fontSize: '14px' }}>Plot No.D-37, Gopalpur, UPSIDA, Near Jain Dharam Kanta , Sikandrabad - UP<br /> <b>Contact No : 8510007640</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Raipur</h6>
                                    <p style={{ fontSize: '14px' }}>Plot No.265/1, 268/1, Behind  J K Video Hall, Dharsiwa - Raipur<br /> <b>Contact No : 7489924744, 7489924741</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Indore</h6>
                                    <p style={{ fontSize: '14px' }}>74, Devguradia Road, Vyapar Vikas Parisar, Indore<br /> <b>Contact No : 7987301846</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Odisha</h6>
                                    <p style={{ fontSize: '14px' }}>Plot No-40, Badadanpur, Puri By Pass Road - Bhubaneswar<br /> <b>Contact No : 9329928089</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Hyderabad</h6>
                                    <p style={{ fontSize: '14px' }}>5-4-23/306, 307, 3rd Floor, Ispat Bhawan, Distellery Road, Ranigunj, Sicundarabad - Hyderabad<br /> <b>Contact No : 9032002475</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Vijayavada</h6>
                                    <p style={{ fontSize: '14px' }}>Door No-76-16-9/A, Bhawanipuram - Vijayavada<br /> <b>Contact No : 9328828085</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Vizag</h6>
                                    <p style={{ fontSize: '14px' }}>A-72, Block -B, Autonagar, Vizag - AP<br /> <b>Contact No : 9329928080</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Anantapur</h6>
                                    <p style={{ fontSize: '14px' }}>D.NO. 78/2 & 78/3, Beside kesava Reddy School, Near Vediampeta - Anantapur<br /> <b>Contact No : 9032002473</b></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8'>
                            <Map />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
