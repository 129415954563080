import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Footer from "../component/Footer";
import About from "../pages/About";
import ErwTubesAndPipes from "../pages/Service/ErwTubesAndPipes";
import MsAngle from "../pages/Service/MsAngle";
import ChannelBeam from "../pages/Service/ChannelBeam";
import HrSheet from '../pages/Service/HrSheet';
import HrCoil from "../pages/Service/HrCoil";
import Contact from "../pages/Contact";
import HeaderNavbar from '../component/HeaderNavbar';
import Chaukhat from "../pages/ValueProduct/chaukhat";
import Alpha from "../pages/ValueProduct/Alpha";
import Plank from "../pages/ValueProduct/Plank";
import Reflecotr from '../pages/ValueProduct/Reflector';
import CSRInivtiative from '../pages/CSRInitiative';
import VAlueAddedProduct from "../pages/ValueProduct/ValueAddeProduct";
import StructuralSteelTubesPipes from "../pages/Product/StructuralSteelTubesPipes";
import MsPipes from "../pages/Product/MsPipes";
import AngleChannelBeam from "../pages/Product/AngleChannelBeam";
import ColourCoatedProducts from "../pages/Product/ColourCoatedProducts";
import MsSheet from "../pages/Product/MsSheet";
import BlogDetailsFactory from '../pages/NewsUpdates/BlogDetailsFactory';


export default function Router() {
    const [loading, setLoading] = useState(true)
    const [headerData, setHeaderData] = useState([]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);


    return (
        <>
            <BrowserRouter>
                {/* <Header setHeaderData={setHeaderData} /> */}
                <HeaderNavbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/product/erw-tubes-pipes" element={<ErwTubesAndPipes />} />
                    <Route path="/product/ms-angle" element={<MsAngle />} />
                    <Route path="/product/channel-beam" element={<ChannelBeam />} />
                    <Route path="/product/hr-sheet" element={<HrSheet />} />
                    <Route path="/product/hr-coil" element={<HrCoil />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/chaukhat" element={<Chaukhat />} />
                    <Route path="/alpha" element={<Alpha />} />
                    <Route path="/plank" element={<Plank />} />
                    <Route path="/reflector" element={<Reflecotr />} />
                    <Route path="/csr-initiative" element={<CSRInivtiative />} />
                    <Route path="/value-added-product" element={<VAlueAddedProduct />} />
                    <Route path="/structural-teel-tubes-and-pipes" element={<StructuralSteelTubesPipes />} />
                    <Route path="/ms-pipes" element={<MsPipes />} />
                    <Route path="/angle-channel-beam" element={<AngleChannelBeam />} />
                    <Route path="/colour-coated-projects" element={<ColourCoatedProducts />} />
                    <Route path="/ms-sheet" element={<MsSheet />} />
                    <Route path="/blog" element={<BlogDetailsFactory />} />

                    {/* <Route path="*" element={<Route404 />} /> */}
                </Routes>
                <Footer headerData={headerData} />
            </BrowserRouter >
        </>
    );
}