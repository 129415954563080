import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap'



export default function Footer() {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [isDisclaimer, setIsDisclaimer] = useState(false);
  useEffect(() => {
    const updateScrollPercentage = () => {
      const scrollPx = document.documentElement.scrollTop;
      const winHeightPx =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (scrollPx / winHeightPx) * 100;

      setScrollPercentage(scrolled);
    };

    window.addEventListener('scroll', updateScrollPercentage);

    return () => {
      window.removeEventListener('scroll', updateScrollPercentage);
    };
  }, []);

  useEffect(() => {
    let time = null;
    const getData = async () => {
      const item = await localStorage.getItem('policy');
      console.log('policy from storage', item);
      if (!item)
        time = setTimeout(() => {
          setIsDisclaimer(true)
        }, 3000);
    }
    getData();
    return () => clearTimeout(time);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setIsDisclaimer(true);
      } else {
        setIsDisclaimer(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleOkayDisclaimer = () => {
    setIsDisclaimer(false);
    localStorage.setItem('policy', 'agreed');
  }

  return (
    <footer className='footer top padding-left-right'>

      <div className="container-fluid p-0">
        <div className='row'>
          <div className="footer-col col-lg-3 col-md-6">
            {/* <div className='footer-logo'>
              <img src={require("../assets/images/logo-w.png")} />
            </div> */}
            <h4>Vikash steel tube pvt Ltd</h4>
            <p>88, Prakash Industrial Estate, Pipe Market Sahibabad - Ghaziabad, 201006</p>
            <ul className='mb-4'>
            </ul>

            {/* <ul>
              <li><a href="mailto:"> </a></li>
              <li><a href="mailto:"></a></li>
            </ul> */}
          </div>
          <div className="footer-col col-lg-3 col-md-6">
            <h4><b>Quicklink</b></h4>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/csr-initiative">CSR Initiative</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>
          <div className="footer-col col-lg-3 col-md-6">
            <h4><b>Our Products</b></h4>
            <ul>
              <li><Link to="/structural-teel-tubes-and-pipes">Structural Steel Tubes & Pipes</Link></li>
              <li><Link to="/ms-pipes">MS Pipes</Link></li>
              <li><Link to="/angle-channel-beam">Angle Channel Beam</Link></li>
              <li><Link to="/colour-coated-projects">Colour Coated Products</Link></li>
              <li><Link to="/ms-sheet">MS Sheet</Link></li>
            </ul>
          </div>
          <div className="footer-col col-lg-3 col-md-6">

            <div class="col-sm-12">
              <h4><b>Follow</b></h4>
              <form className='footer-form'>
                {/* <input type="email" placeholder="Email" className="inptEmail" required />
                <button className='btn footer-btn btn-class'>Subscribe<span>Subscribe</span></button>
                <p className='mt-0 pt-0'>* we respact your privacy</p> */}
                <ul className='mb-4'>
                  <li><a href="tel:18005695695"><i className="fa-solid fa-phone"></i> 1800 569 5695</a></li>
                  <li><a href="mailto:info@vikashsteel.in"><i class="fa fa-envelope" aria-hidden="true"></i> info@vikashsteel.in</a></li>
                </ul>
              </form>
            </div>

            <div className="social-links">
              <li>
                <a href="https://www.facebook.com/profile.php?id=61565893262097" target='_blank'><i className="fa-brands fa-facebook-f"></i></a>
              </li>
              <li>
                <a href="https://www.instagram.com/vikashsteel.pvt/" target='_blank'><i className="fa-brands fa-instagram"></i></a>
              </li>
              <li>
                <a href="www.linkedin.com/in/vikash-steel-553411329" target='_blank'><i className="fa-brands fa-linkedin-in"></i></a>
              </li>
              <li>
                <a href="https://x.com/steel_vikash" target='_blank'><i className="fa-brands fa-twitter"></i></a>
              </li>
              <li>
                <a href="https://www.youtube.com/@VikashSteel" target='_blank'><i className="fa-brands fa-youtube"></i></a>
              </li>
            </div>
          </div>
        </div>


      </div>
      <div className='container-fluid'>
        <div className='footer-copyright row'>
          <div className='col-lg-6 p-0'>
            <div className='copy-right-p'>
              <p> &copy;2024 VIKASH STEEL TUBE PVT LTD </p>
            </div>
          </div>
          {/* <div className='col-lg-4 p-0'>
            <div className='privacy-policy'>
              <p><Link to='/'>Privacy Policy</Link></p>
            </div>
          </div> */}
          <div className='col-lg-6 p-0'>
            <div className='website-link'>
              <p>Website Design & Developed by <a href='https://www.winklix.com/' target='_blank'>Winklix</a></p>
            </div>
          </div>
        </div>
      </div>

      <div class="progress-circle-container">

        <svg viewBox="0 0 36 36" className="circular-chart">
          <path
            className="circle-bg"
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle"
            strokeDasharray={`${scrollPercentage}, 100`}
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>

        <div className="uparrow">
          <a href="#"><i className="fa fa-arrow-up"></i></a>
        </div>

      </div>
      {/* <div className={isDisclaimer ? 'cookie-section active' : 'cookie-section'}>
        <div>
          <h4>Agree to cookies</h4>
          <p>" a leading marketing agency based in Gurgaon, specializing in innovative branding solutions tailored to elevate your business presence. With a strategic blend of creativity and analytics, we craft compelling narratives that resonate with your audience, driving impactful results."</p>
        </div>
        <button onClick={handleOkayDisclaimer}>OK, I got it</button>
      </div> */}
    </footer>
  )
}


